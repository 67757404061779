const randomXY = (arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) => {
  const output1 = arr[Math.floor(Math.random() * arr.length)];
  return [output1, 0, output1 + 4];
};
const randomRotation = (arr = [45, 15, 255, 155, 78, 99, 105]) => {
  const output = arr[Math.floor(Math.random() * arr.length)];
  return [output, output];
};

export const MainCardVariants = {
  in: { opacity: [0, 0.5, 1], scale: [1.2, 1] },
  out: { opacity: [1, 0.5, 0], scale: [1, 1.2] },
};

export const BreathingVariants = {
  initial: {
    x: 0,
    y: 0
  },
  transition: {
    repeat: Infinity,
    repeatType: "mirror",
    duration: 3
  },
  breathing1: {
    y: 5
  },
  breathing2: {
    y: -5,
  },
  breathing3: {
    x: -5,
    y: -5
  },
  breathing4: {
    x: 5,
    y: 5
  }
};
