import { AnimatePresence, motion } from "framer-motion";
import { Analytics } from '@vercel/analytics/react';
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Navbar from "../src/components/Navbar";
import Footer from "../src/components/Footer";
import "../styles/globals.scss";

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const [globalLoading, setGlobalLoading] = useState(false);

  const toggleGlobalLoader = (loading = false) => {
    const globalLoader = document.getElementById("globalLoader");
    const globalLoadingEl1 = document.getElementById("loader1");
    const globalLoadingEl2 = document.getElementById("loader2");
    const textEl = document.getElementById("text");
    if (loading) {
      textEl.style.display = "block";
      globalLoader.style.display = "flex";
      globalLoadingEl1.style.translate = "5%";
      globalLoadingEl2.style.translate = "0%";
      textEl.style.translate = "0 0";
    } else {
      globalLoadingEl1.style.translate = "-650%";
      globalLoadingEl2.style.translate = "650%";
      textEl.style.translate = "0 10rem";
      setTimeout(() => {
        textEl.style.display = "none";
        globalLoader.style.display = "none";
      }, 2000);
    }
  }

  useEffect(() => {
    toggleGlobalLoader(globalLoading);
  }, [globalLoading]);

  pageProps = {
    ...pageProps,
    globalLoading,
    setGlobalLoading,
    toggleGlobalLoader,
  };
  
  return (
    <AnimatePresence mode="wait" initial={false}>
      <Head>
        <title>Brian Harianja - Software Engineer from Indonesia</title>
        <meta name="title" content="Brian Harianja" />
        <meta name="description" content="Hi, I'm Brian Harianja. A fast learner with high curiousity in technology. Currently working from Indonesia and a dog lover." />
        <meta name="type" content="website" />
        <meta name="url" content="https://brian.madeit.cool/" />
        <meta name="image" content="https://brian.madeit.cool/img/jogja.jpg" />
        <meta name="keyword" content="Frontend Developer Indonesia" />
        <meta property="og:title" content="Brian Harianja" />
      
        <meta property="og:description" content="Hi, I'm Brian Harianja. A fast learner with high curiousity in technology. Currently working from Indonesia and a dog lover." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://brian.madeit.cool/" />
        <meta property="og:image" content="https://brian.madeit.cool/img/jogja.jpg" />
        <meta property="og:keywords" content="Frontend Developer Indonesia" />
        <script src="/script/three.min.js"></script>
        <script src="/script/dots.min.js"></script>
      </Head>
      <motion.div
        key={router.asPath}
      >
        <motion.div
          className="fixed top-0 left-0 z-50 w-full h-screen bg-primary/50"
          initial={{ y: "50%" }}
          animate={{ y: "100%" }}
          exit={{ y: "50%" }}
          transition={{
            ease: "easeOut",
            duration: 1.5
          }}
        />
        <motion.div
          className="fixed top-0 left-0 z-50 w-full h-screen bg-primary/75"
          initial={{ y: "50%" }}
          animate={{ y: "100%" }}
          exit={{ y: "50%" }}
          transition={{
            ease: "easeOut",
            duration: 1.0
          }}
        />
        <motion.div
          className="fixed top-0 left-0 z-50 w-full h-screen bg-primary"
          initial={{ y: "50%" }}
          animate={{ y: "100%" }}
          exit={{ y: "50%" }}
          transition={{
            ease: "easeOut",
            duration: 0.5
          }}
        />

        <motion.div
          className="fixed top-0 left-0 z-50 w-full h-screen bg-primary/50"
          initial={{ y: "-50%" }}
          animate={{ y: "-100%" }}
          exit={{ y: "-50%" }}
          transition={{
            ease: "easeOut",
            duration: 1.5
          }}
        />
        <motion.div
          className="fixed top-0 left-0 z-50 w-full h-screen bg-primary/75"
          initial={{ y: "-50%" }}
          animate={{ y: "-100%" }}
          exit={{ y: "-50%" }}
          transition={{
            ease: "easeOut",
            duration: 1.0
          }}
        />
        <motion.div
          className="fixed top-0 left-0 z-50 w-full h-screen bg-primary"
          initial={{ y: "-50%" }}
          animate={{ y: "-100%" }}
          exit={{ y: "-50%" }}
          transition={{
            ease: "easeOut",
            duration: 0.5
          }}
        />
        <Navbar />
        <AnimatePresence key={router.asPath}>
          <Component {...pageProps} />
        </AnimatePresence>
        <Footer />
      </motion.div>
      <Analytics key={`analytics-${router.asPath}`} />
    </AnimatePresence>
  );
};

export default MyApp;
