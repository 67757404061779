const Footer = (p) => {
  return (
    <footer className="container mt-32 bg-gray-200 shadow-inner rounded-t-xl relative no-scroll">
        <div className="py-8 flex justify-between items-center">
          <h6>© 2023 - Brian Harianja</h6>
          <div className="flex gap-x-3">
            <span>Links will be here soon!</span>
          </div>
        </div>
        <img
          className="h-44"
          src="/img/footer.png"
          alt="Probably me, working. Lol."
        />
        <div className="absolute w-full h-full text-center">
          <p>
            You found me.
          </p>
        </div>
    </footer>
  );
};

export default Footer;
