import { motion } from "framer-motion";
import Link from "next/link";
import { NavBarVariants } from "../../../motion.variants";

const Navbar = (p) => {
  return (
    <nav className="w-screen px-8 fixed z-40 top-3 text-white mix-blend-difference">
      <div className="w-full bg-glass container px-6 lg:px-44">
        <div className="flex justify-between">
          <Link href="/" className="font-bold py-3 text-2xl">standinshd</Link>
          <div className="flex items-center">
            <p className="mr-2">contact me</p>
            {/* <p className="mr-2">fb</p>
            <p className="mr-2">fb</p>
            <p className="mr-2">fb</p> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
